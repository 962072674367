// @ts-nocheck
import Link from 'next/link'
import clsx from 'clsx'
import { cn } from '@/lib/utils'

type ButtonProps = {
  invert?: boolean
  flip?: boolean
  childrenClass?: string
} & (
  | React.ComponentPropsWithoutRef<typeof Link>
  | (React.ComponentPropsWithoutRef<'button'> & { href?: undefined })
)

export function Button({
  invert = false,
  className,
  children,
  flip = false,
  pro = false,
  childrenClass,
  ...props
}: ButtonProps) {
  className = clsx(
    className,
    'inline-flex rounded-full px-3 py-1.5 text-sm font-semibold  transition input-shadow-glow',

    // invert
    //   ? 'bg-white text-neutral-950 hover:bg-neutral-200 border border-white/10 dark:border-black/10'
    //   : 'bg-gradient-to-br from-vanta-850 to-vanta-700 dark:bg-vanta-600/60  text-white hover:bg-vanta-800 dark:hover:bg-vanta-700 border shadow-sm border-white/20 dark:border-white/20',
    invert
      ? 'bg-white text-neutral-950 hover:bg-neutral-200 border border-white/10 dark:border-black/10'
      : flip
      ? 'bg-gradient-to-br from-vanta-600/90 to-stone-800 text-white hover:bg-gradient-to-br hover:from-vanta-600/90 hover:to-stone-800 border shadow-sm  border-white/10 '
      : pro
      ? 'bg-gradient-to-br from-brandBlue-400/90 to-brandBlue-500 text-white hover:bg-gradient-to-br hover:from-brandBlue-500/90 hover:to-brandBlue-600 border shadow-sm  border-white/10 '
      : 'bg-gradient-to-br from-neutral-100 to-white dark:bg-gradient-to-br dark:from-vanta-600/90 dark:to-stone-800  text-black dark:text-white hover:bg-gradient-to-br hover:from-neutral-200 hover:to-neutral-100 dark:hover:bg-vanta-700 border shadow-sm border-black/10 dark:border-white/10',
  )

  let inner = <span className={cn('relative ', childrenClass)}>{children}</span>

  if (typeof props.href === 'undefined') {
    return (
      <button className={className} {...props}>
        {inner}
      </button>
    )
  }

  return (
    <Link className={className} {...props}>
      {inner}
    </Link>
  )
}

export function CTAButton({
  invert = false,
  className,
  children,
  flip = false,
  childrenClass,
  ...props
}: ButtonProps) {
  className = cn(
    className,
    'inline-flex rounded-full px-4  text-sm font-semibold  transition input-shadow-glow flex items-center justify-center',

    invert
      ? 'bg-gradient-to-t  from-stone-900 from-0% to-ash-900 py-2 dark:bg-gradient-to-br dark:from-vanta-600/90 dark:to-stone-800  text-white dark:text-white hover:bg-gradient-to-t hover:from-stone-800 hover:to-ash-800 dark:hover:bg-vanta-700 border shadow-sm border-black/10 dark:border-white/10'
      : flip
      ? 'bg-gradient-to-br from-vanta-600/90 to-stone-800  text-white hover:bg-gradient-to-br hover:from-vanta-600/90 hover:to-stone-800 border shadow-sm  border-white/10 '
      : 'bg-gradient-to-t  from-brand-400 from-0% to-brand-200 py-2  dark:bg-gradient-to-t dark:from-brand-600/90 dark:to-brand-500  text-black dark:text-white hover:bg-gradient-to-t hover:from-brand-300 hover:to-brand-300 dark:hover:bg-vanta-700 border shadow-sm border-black/10 dark:border dark:border-brand/10',
  )

  let inner = <span className={cn('relative ', childrenClass)}>{children}</span>

  if (typeof props.href === 'undefined') {
    return (
      <button className={className} {...props}>
        {inner}
      </button>
    )
  }

  return (
    <Link className={className} {...props}>
      {inner}
    </Link>
  )
}
