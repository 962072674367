'use client'

import { Dispatch, ReactNode, SetStateAction, createContext } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'

import { ThemeProvider, useTheme } from 'next-themes'
import { Toaster } from 'sonner'
import { ThemeProviderProps } from 'next-themes/dist/types'

export const AppContext = createContext<{
  font: string
  setFont: Dispatch<SetStateAction<string>>
}>({
  font: 'Default',
  setFont: () => {},
})

const getPageTheme = (
  pathname: string,
  searchParams,
): ThemeProviderProps['forcedTheme'] => {
  // Add pathnames without a '/' prefix here.
  // '' matches the homepage.
  const darkModePages = ['']
  const lightModePages = ['', 'marketplace']

  // Exact matches, more strict
  if (
    darkModePages.some((name) => pathname === `/${name}`) &&
    lightModePages.some((name) => pathname === `/${name}`)
  ) {
    return
  }
  if (darkModePages.some((name) => pathname === `/${name}`)) return 'dark'
  if (lightModePages.some((name) => pathname === `/${name}`)) return 'light'

  return
}

const ToasterProvider = () => {
  const { theme } = useTheme() as {
    theme: 'light' | 'dark' | 'system'
  }
  return <Toaster richColors theme={theme} />
}

export default function Providers({ children }: { children: ReactNode }) {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  // const postLoginTheme = searchParams.get('postLoginTheme')

  // const [font, setFont] = useLocalStorage<string>('novel__font', 'Default');

  return (
    <ThemeProvider
      attribute="class"
      value={{
        light: 'light-theme',
        dark: 'dark-theme',
      }}
      // @ts-ignore
      forcedTheme={pathname === '/' ? 'light' : null}
    >
      <ToasterProvider />
      {/* <EdgeStoreProvider>{children}</EdgeStoreProvider> */}

      {children}
    </ThemeProvider>
  )
}
