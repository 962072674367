import { v4 as uuidv4 } from 'uuid'
import { twMerge } from 'tailwind-merge'
import { customAlphabet } from 'nanoid'
import { type ClassValue, clsx } from 'clsx'
import { NextApiRequest, NextApiResponse } from 'next'

export const uniqueCourseId = uuidv4()

export function isValidUUID(uuid) {
  const regexExp =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
  return regexExp.test(uuid)
}

export const nanoid = customAlphabet(
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
  7,
) // 7-character random string

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function getUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.NEXT_PUBLIC_APP_URL || 'http://localhost:3000'
  } else {
    return process.env.NEXT_PUBLIC_WEB_URL || 'https://gnow.io'
  }
}

// function getBaseURL() {
//   if (typeof window !== 'undefined') {
//     return ''
//   }
//   if (process.env.VERCEL_URL) {
//     return `https://${process.env.VERCEL_URL}`
//   }
//   return 'http://localhost:3000'
// }
// const baseUrl = getBaseURL()

export function pluralize(
  word: string,
  count: number,
  pluralForm?: string,
): string {
  if (count === 1) {
    return word
  }

  if (pluralForm) {
    return pluralForm
  }

  return `${word}s`
}

export function isValidUrl(urlString: string): boolean {
  try {
    new URL(urlString)
    return true
  } catch {
    return false
  }
}

function removeLeadingDash(pathPart: string): string {
  return pathPart.startsWith(` - `) ? pathPart.substring(1) : pathPart
}

function getSecondLevelDomain(hostname: string): string {
  return hostname.split('.').slice(-2, -1).join('.')
}

function removeFileExtension(pathPart: string | undefined): string {
  if (!pathPart) return ''
  return pathPart.replace(/\.[^/.]+$/, '')
}

function shortenLastPathPart(pathPart: string, maxLength: number): string {
  if (!pathPart) return ''
  return pathPart.length > maxLength
    ? pathPart.substring(0, maxLength)
    : pathPart
}

export function truncateLongUrl(
  longUrl: string,
  maxLength: number = 15,
): string {
  if (!isValidUrl(longUrl)) return 'Invalid URL'

  const cleanUrl = removeLeadingDash(longUrl)
  const url = new URL(cleanUrl)
  const hostname = getSecondLevelDomain(url.hostname)
  const pathParts = url.pathname.split('/').filter((part) => part !== '')
  let lastPathPart = pathParts[pathParts.length - 1]

  lastPathPart = removeFileExtension(lastPathPart)
  lastPathPart = shortenLastPathPart(lastPathPart, maxLength)

  const shortUrl = `${hostname}${lastPathPart ? '/' : ''}${lastPathPart}`
  //   return shortUrl
  return shortUrl.replace(/-/g, '')
}

export function truncateLongFileName(
  fileName: string,
  maxLength: number = 15,
): string {
  const fileBaseName = fileName.replace(/\.[^/.]+$/, '') // Remove file extension
  const hyphenatedName = fileBaseName.replace(/\W+/g, '-') // Replace non-word characters with hyphens
  const truncatedName = hyphenatedName.slice(0, maxLength) // Truncate to the specified maxLength
  const cleanTruncatedName = truncatedName.replace(/-$/, '') // Remove trailing hyphen if present

  // Add back the file extension
  const fileExtension = fileName.match(/\.[^/.]+$/) || ['']
  const truncatedFileName = `${cleanTruncatedName}${fileExtension[0]}`

  return truncatedFileName
}

// A function that takes a file name and a string and returns true if the file name is contained in the string
// after removing punctuation and whitespace from both
export const isFileNameInString = (fileName: string, str: string) => {
  // Check if the input string is null or undefined
  if (!str) {
    return false
  }

  // Convert both to lowercase and remove punctuation and whitespace
  const normalizedFileName = fileName
    .toLowerCase()
    .replace(/[.,/#!$%^&*;:{}=-_~()\s]/g, '')
  const normalizedStr = str
    .toLowerCase()
    .replace(/[.,/#!$%^&*;:{}=-_~()\s]/g, '')

  // Return true if the normalized file name is included in the normalized string
  return normalizedStr.includes(normalizedFileName)
}

export async function fetcher<JSON = any>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<JSON> {
  const res = await fetch(input, init)

  if (!res.ok) {
    const json = await res.json()
    if (json.error) {
      const error = new Error(json.error) as Error & {
        status: number
      }
      error.status = res.status
      throw error
    } else {
      throw new Error('An unexpected error occurred')
    }
  }

  return res.json()
}

export function formatDate(input: string | number | Date): string {
  const date = new Date(input)
  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })
}

/**
 * Sanitizes input by stripping potentially harmful characters.
 *
 * This function is used to prevent potential injection attacks
 * or other unintended operations.
 *
 * @param {string | null | undefined} value - The value to sanitize.
 * @returns {string} - The sanitized string.
 */
export const sanitize = (value: string | null | undefined): string => {
  if (typeof value !== 'string') return ''
  return value.replace(/[<>"'`]/g, '')
}

/**
 * Higher-order function to handle common CRUD operations for a Next.js API route.
 * This function streamlines error handling and response formatting for CRUD operations.
 * By wrapping your CRUD functions with this HOC, it ensures consistent response formatting
 * and error handling across different API endpoints.
 
 * @param {Function} fn - The actual CRUD operation function that needs to be executed.
 * @returns {Function} - A new function that wraps the passed CRUD function with
 *                       standard error handling and response formatting.
 */
export const handle =
  (fn: (req: NextApiRequest) => Promise<any>) =>
  async (req: NextApiRequest, res: NextApiResponse) => {
    try {
      res.status(200).json(await fn(req))
    } catch (err) {
      res.status(500).json({
        error: err instanceof Error ? err.message : 'Unknown error occurred.',
      })
    }
  }

export function getUrlFromString(str: string) {
  if (isValidUrl(str)) return str
  try {
    if (str.includes('.') && !str.includes(' ')) {
      return new URL(`https://${str}`).toString()
    }
  } catch (e) {
    return null
  }
}

export function formatDateShort(version: string): string {
  const date = new Date(version)

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const day = date.getDate()
  const month = monthNames[date.getMonth()]
  const year = date.getFullYear()

  return `${month} ${day}, ${year}`
}
